/* CSS Variables for theming */
:root {
  /* Color system - Enhanced with HSL for better color manipulation */
  --primary-hue: 210;
  --primary-saturation: 29%;
  --primary-lightness: 24%;
  --primary-color: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-lightness));
  --primary-color-light: hsl(var(--primary-hue), 50%, 80%);
  --primary-color-dark: hsl(var(--primary-hue), 60%, 44%);
  
  --accent-hue: 204;
  --accent-saturation: 70%;
  --accent-lightness: 53%;
  --accent-color: hsl(var(--accent-hue), var(--accent-saturation), var(--accent-lightness));
  --accent-color-light: hsl(var(--accent-hue), 50%, 80%);
  --accent-color-dark: hsl(var(--accent-hue), 60%, 44%);
  
  /* Semantic colors */
  --success-color: hsl(134, 51%, 45%);
  --warning-color: hsl(42, 100%, 50%);
  --error-color: hsl(0, 72%, 51%);
  --info-color: hsl(210, 76%, 47%);
  
  /* Background colors */
  --bg-color: hsl(0, 0%, 100%);
  --bg-color-dark: hsl(0, 0%, 10%);
  --bg-light: hsl(210, 20%, 98%);
  --bg-secondary: hsl(210, 17%, 98%);
  --bg-secondary-dark: hsl(215, 19%, 28%);
  
  /* Card backgrounds */
  --card-bg: hsl(0, 0%, 100%);
  --card-bg-light: hsl(210, 17%, 98%);
  --card-bg-dark: hsl(218, 23%, 23%);
  
  /* Text colors */
  --text-color: hsl(0, 0%, 20%);
  --text-color-light: hsl(210, 7%, 45%);
  --text-color-dark: hsl(210, 17%, 98%);
  --text-primary: hsl(210, 29%, 24%);
  --text-secondary: hsl(210, 8%, 45%);
  --text-tertiary: hsl(0, 0%, 47%);
  
  /* Border colors */
  --border-color: hsl(0, 0%, 88%);
  --border-color-dark: hsl(218, 17%, 35%);
  
  /* Hover states */
  --hover-bg: hsl(210, 17%, 95%);
  --hover-bg-dark: hsl(218, 17%, 35%);
  
  /* Shadows with alpha transparency for better performance */
  --shadow-sm: 0 1px 3px hsla(0, 0%, 0%, 0.1);
  --shadow-md: 0 4px 6px hsla(0, 0%, 0%, 0.1);
  --shadow-lg: 0 10px 15px hsla(0, 0%, 0%, 0.1);
  --card-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.08);
  --card-shadow-hover: 0 4px 12px hsla(0, 0%, 0%, 0.12);
  
  /* Border radius - consolidated system */
  --radius-sm: 0.25rem;
  --radius-md: 0.5rem;
  --radius-lg: 0.75rem;
  --radius-xl: 1rem;
  --radius-pill: 9999px;
  
  /* Language-specific colors - organized together */
  --language-latin: hsl(214, 38%, 47%);
  --language-greek: hsl(271, 49%, 51%);
  --language-germanic: hsl(28, 80%, 52%);
  --language-romance: hsl(0, 67%, 47%);
  --language-slavic: hsl(145, 63%, 42%);
  --language-sanskrit: hsl(291, 64%, 42%);
  --language-proto: hsl(120, 45%, 34%);
  
  /* Spacing system - using clamp for responsive values */
  --space-3xs: 0.125rem;
  --space-2xs: 0.25rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-md: 1rem;
  --space-lg: 1.5rem;
  --space-xl: 2rem;
  
  /* Typography scale - using clamp for fluid typography */
  --text-xs: clamp(0.75rem, 0.7vw, 0.875rem);     /* 12-14px */
  --text-sm: clamp(0.875rem, 0.8vw, 1rem);    /* 14-16px */
  --text-base: clamp(1rem, 1vw, 1.125rem);      /* 16-18px */
  --text-lg: clamp(1.125rem, 1.2vw, 1.25rem);    /* 18-20px */
  --text-xl: clamp(1.25rem, 1.4vw, 1.5rem);     /* 20-24px */
  --text-2xl: clamp(1.5rem, 1.8vw, 1.875rem);     /* 24-30px */
  --text-3xl: clamp(1.875rem, 2.4vw, 2.25rem);   /* 30-36px */
  
  /* Line heights - improved for better readability */
  --leading-none: 1;
  --leading-tight: 1.25;
  --leading-snug: 1.375;
  --leading-normal: 1.5;
  --leading-relaxed: 1.625;
  --leading-loose: 2;
  
  /* Font weights */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  
  /* Transitions - consolidated */
  --transition-fast: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-normal: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-slow: 500ms cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Z-index scale */
  --z-below: -1;
  --z-normal: 0;
  --z-above: 1;
  --z-header: 10;
  --z-tooltip: 20;
  --z-modal: 30;
  --z-toast: 40;
  --z-max: 9999;

  /* Fonts */
  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  --font-display: 'Plus Jakarta Sans', var(--font-sans);
  --font-mono: 'JetBrains Mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  
  /* Card styling */
  --card-padding: 1rem;
  --card-border: 1px solid hsla(0, 0%, 0%, 0.08);
  --section-spacing: var(--space-lg);
  --inner-spacing: var(--space-sm);
  --small-spacing: var(--space-xs);
}

/* Dark mode variables - improved contrast and readability */
.dark {
  /* Color system */
  --primary-hue: 210;
  --primary-saturation: 80%;
  --primary-lightness: 72%;
  --primary-color: hsl(var(--primary-hue), var(--primary-saturation), var(--primary-lightness));
  --primary-color-light: hsl(var(--primary-hue), 60%, 25%);
  --primary-color-dark: hsl(var(--primary-hue), 90%, 80%);
  
  --accent-hue: 210;
  --accent-saturation: 80%;
  --accent-lightness: 72%;
  --accent-color: hsl(var(--accent-hue), var(--accent-saturation), var(--accent-lightness));
  --accent-color-light: hsl(var(--accent-hue), 60%, 25%);
  --accent-color-dark: hsl(var(--accent-hue), 90%, 80%);
  
  /* Background colors */
  --bg-color: hsl(220, 40%, 8%);
  --bg-color-dark: hsl(220, 40%, 5%);
  --bg-light: hsl(220, 20%, 15%);
  --bg-secondary: hsl(218, 23%, 23%);
  --bg-secondary-dark: hsl(220, 26%, 14%);
  
  /* Card backgrounds */
  --card-bg: hsl(218, 23%, 23%);
  --card-bg-light: hsl(218, 17%, 27%);
  --card-bg-dark: hsl(220, 26%, 14%);
  
  /* Text colors - improved contrast */
  --text-color: hsl(210, 17%, 98%);
  --text-color-light: hsl(210, 16%, 82%);
  --text-color-dark: hsl(210, 20%, 92%);
  --text-primary: hsl(0, 0%, 88%);
  --text-secondary: hsl(0, 0%, 69%);
  --text-tertiary: hsl(0, 0%, 56%);
  
  /* Border colors */
  --border-color: hsl(218, 17%, 35%);
  --border-color-dark: hsl(218, 23%, 23%);
  
  /* Hover states */
  --hover-bg: hsl(218, 17%, 35%);
  --hover-bg-dark: hsl(218, 23%, 23%);
  
  /* Shadows - adjusted for dark mode */
  --shadow-sm: 0 1px 3px hsla(0, 0%, 0%, 0.3);
  --shadow-md: 0 4px 6px hsla(0, 0%, 0%, 0.3);
  --shadow-lg: 0 10px 15px hsla(0, 0%, 0%, 0.3);
  --card-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.4);
  --card-shadow-hover: 0 4px 12px hsla(0, 0%, 0%, 0.5);
  
  /* Card styling */
  --card-border: 1px solid hsla(0, 0%, 100%, 0.05);
}

/* Accessibility - Reduced motion preference */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Base styles */
html {
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-sans);
  font-size: var(--text-base);
  line-height: var(--leading-normal);
  color: var(--text-color);
  background-color: var(--bg-color);
  transition: 
    color var(--transition-normal),
    background-color var(--transition-normal);
}

/* Focus styles for accessibility */
:focus-visible {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

/* Skip to content link for accessibility */
.skip-to-content {
  position: absolute;
  top: -9999px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--accent-color);
  color: white;
  padding: var(--space-xs) var(--space-md);
  z-index: var(--z-max);
  text-decoration: none;
  border-radius: 0 0 var(--radius-md) var(--radius-md);
}

.skip-to-content:focus {
  top: 0;
}

/* Error message styling */
.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-message p {
  margin-bottom: 10px;
}

.error-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

/* Backend instructions styling */
.backend-instructions {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin: 10px 0;
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.backend-instructions p {
  margin-bottom: 10px;
  color: #333;
}

.backend-instructions ol {
  margin-left: 20px;
  color: #333;
}

.backend-instructions code {
  background-color: #e9ecef;
  padding: 2px 5px;
  border-radius: 3px;
  font-family: monospace;
  color: #d63384;
}

/* Button styles */
.reset-button {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #1976d2;
}

.debug-button {
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.debug-button:hover {
  background-color: #f57c00;
}

.retry-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #388e3c;
}

/* Header styling */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

/* API status indicators */
.api-status {
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.api-status.connected {
  background-color: #e8f5e9;
  border-color: #a5d6a7;
  color: #2e7d32;
}

.api-status.disconnected {
  background-color: #ffebee;
  border-color: #ef9a9a;
  color: #c62828;
}

.api-status.checking {
  background-color: #e3f2fd;
  border-color: #90caf9;
  color: #1565c0;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Enhanced Word Details Container */
.word-details {
  background-color: var(--card-bg);
  border-radius: var(--radius-md);
  padding: 0;
  box-shadow: var(--card-shadow);
  margin-bottom: var(--space-lg);
  position: relative;
  overflow: hidden;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  border: var(--card-border);
  max-width: 100%;
  contain: content; /* Improve performance with CSS containment */
  will-change: transform; /* Optimize for animations */
}

.word-details:hover {
  box-shadow: var(--card-shadow-hover);
  transform: translateY(-2px);
}

/* Word Header Styling */
.word-header {
  background: linear-gradient(135deg, 
    hsla(var(--accent-hue), var(--accent-saturation), var(--accent-lightness), 0.15) 0%, 
    hsla(var(--accent-hue), calc(var(--accent-saturation) - 20%), calc(var(--accent-lightness) - 10%), 0.02) 100%);
  padding: var(--space-md) var(--space-md) var(--space-sm);
  margin-bottom: 0;
  position: relative;
  border-bottom: none;
  box-shadow: 0 1px 8px hsla(0, 0%, 0%, 0.05);
  border-top-left-radius: var(--radius-md);
  border-top-right-radius: var(--radius-md);
}

.word-header h2 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--accent-color-dark);
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-family: var(--font-display, sans-serif);
  position: relative;
  display: inline-block;
  border-bottom: 1px solid var(--accent-color);
  padding-bottom: 0.25rem;
  transition: transform 0.2s ease, color 0.2s ease;
}

.word-header h2:hover {
  transform: translateY(-1px);
  color: var(--accent-color);
}

/* Word Meta Information */
.word-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}

.language {
  background-color: hsla(var(--primary-hue), var(--primary-saturation), var(--primary-lightness), 0.1);
  color: var(--primary-color);
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.125rem 0.5rem;
  border-radius: 9999px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.dark .language {
  background-color: hsla(var(--primary-hue), calc(var(--primary-saturation) - 30%), calc(var(--primary-lightness) + 10%), 0.2);
  color: hsl(var(--primary-hue), calc(var(--primary-saturation) - 10%), calc(var(--primary-lightness) + 20%));
}

.baybayin-badge {
  background-color: rgba(230, 119, 0, 0.06);
  color: var(--color-etymology, #2a9d8f);
  padding: 0.15rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  border: 1px solid rgba(230, 119, 0, 0.12);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.baybayin-badge:hover {
  background-color: rgba(230, 119, 0, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.preferred-spelling {
  background-color: rgba(76, 175, 80, 0.1);
  color: #2e7d32;
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  border: 1px solid rgba(76, 175, 80, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
}

.tag {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--text-secondary, #6c757d);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2xs);
}

.tag {
  background-color: rgba(0, 0, 0, 0.03);
  color: var(--text-secondary);
  padding: 0.1rem 0.4rem;
  border-radius: var(--radius-sm);
  font-size: var(--text-xs);
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

/* Enhanced Pronunciation Section */
.pronunciation-section {
  margin: 0;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, rgba(74, 111, 165, 0.05), rgba(74, 111, 165, 0.01));
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  flex-direction: column;
}

.pronunciation-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(74, 111, 165, 0.2) 0%, transparent 100%);
}

.pronunciation-section h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: var(--accent-color-dark, #1d3557);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  display: flex;
  align-items: center;
}

.pronunciation-section h3::before {
  content: "🔊";
  font-size: 0.8rem;
  margin-right: 0.5rem;
  opacity: 0.8;
}

.pronunciation-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.pronunciation-text {
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  color: var(--text-primary, #1d3557);
  background-color: rgba(74, 111, 165, 0.04);
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease;
}

.pronunciation-text:hover {
  background-color: rgba(74, 111, 165, 0.08);
  transform: translateY(-1px);
}

.ipa {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.15rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-family: monospace;
  color: var(--text-secondary, #6c757d);
  border: 1px solid rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}

.ipa:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.play-audio {
  background-color: var(--accent-color-light, #a8dadc);
  color: var(--accent-color-dark, #1d3557);
  border: none;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.play-audio:hover {
  background-color: var(--accent-color, #457b9d);
  color: white;
  transform: scale(1.15);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
}

/* Content styling */
.word-content {
  padding: var(--space-md);
  background-color: white;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
}

/* Etymology section */
.etymology-section {
  margin-bottom: var(--space-md);
  padding: var(--space-sm);
  background-color: rgba(230, 119, 0, 0.03);
  border-radius: var(--radius-md);
  border-left: 3px solid var(--color-etymology);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  position: relative;
  overflow: hidden;
}

.etymology-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle at top right, rgba(230, 119, 0, 0.06) 0%, transparent 70%);
  z-index: 0;
}

.etymology-section h3 {
  margin-top: 0;
  margin-bottom: var(--space-xs);
  font-size: var(--text-sm);
  color: var(--color-etymology);
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(230, 119, 0, 0.08);
  padding-bottom: var(--space-xs);
  position: relative;
}

.etymology-section h3::before {
  content: "🔍";
  margin-right: var(--space-xs);
  font-size: var(--text-sm);
}

.etymology-content {
  font-size: var(--text-sm);
  line-height: 1.5;
  position: relative;
  z-index: 1;
}

.etymology-item {
  margin-bottom: var(--space-xs);
  padding: var(--space-sm);
  background-color: white;
  border-radius: var(--radius-md);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(230, 119, 0, 0.08);
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;
}

.etymology-item:hover {
  transform: translateY(-2px);
  border-color: rgba(230, 119, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}

.etymology-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, var(--color-etymology) 0%, transparent 100%);
  opacity: 0.3;
}

.etymology-text {
  margin-bottom: var(--space-xs);
  line-height: 1.5;
  color: var(--text-primary);
  font-size: var(--text-sm);
}

.etymology-languages {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2xs);
  margin-bottom: var(--space-xs);
}

.language-tag {
  background-color: rgba(230, 119, 0, 0.06);
  color: var(--color-etymology);
  padding: 0.1rem 0.4rem;
  border-radius: var(--radius-sm);
  font-size: var(--text-xs);
  font-weight: 500;
  border: 1px solid rgba(230, 119, 0, 0.12);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  transition: all 0.2s ease;
}

.language-tag:hover {
  background-color: rgba(230, 119, 0, 0.1);
  transform: translateY(-1px);
}

.etymology-sources {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2xs);
  align-items: center;
  padding-top: var(--space-xs);
  border-top: 1px dashed rgba(230, 119, 0, 0.1);
  margin-bottom: var(--space-xs);
}

.etymology-components-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  padding-top: var(--space-xs);
  border-top: 1px dashed rgba(230, 119, 0, 0.1);
}

.components-label {
  color: var(--text-secondary);
  font-weight: 500;
  font-size: var(--text-xs);
}

.source-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2xs);
}

.source-tag {
  background-color: rgba(0, 0, 0, 0.02);
  color: var(--text-secondary);
  padding: 0.1rem 0.4rem;
  border-radius: var(--radius-sm);
  font-size: var(--text-xs);
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.01);
}

.source-tag:hover {
  background-color: var(--accent-color-light);
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Definitions section */
.definitions-section {
  margin-bottom: 1.5rem;
}

.definitions-section-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid rgba(74, 111, 165, 0.08);
  padding-bottom: 0.5rem;
  position: relative;
}

.definitions-section-header::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 60px;
  height: 2px;
  background-color: var(--accent-color, #457b9d);
  border-radius: 2px;
}

.definitions-section-header h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--accent-color-dark, #1d3557);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.definition-count {
  background-color: var(--accent-color-light, #a8dadc);
  color: var(--accent-color-dark, #1d3557);
  padding: 0.1rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pos-group {
  margin-bottom: 1.5rem;
  background-color: rgba(74, 111, 165, 0.02);
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.pos-group-header {
  background: linear-gradient(90deg, rgba(74, 111, 165, 0.1) 0%, rgba(74, 111, 165, 0.05) 100%);
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--accent-color-dark, #1d3557);
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  font-size: 0.875rem;
}

.pos-count {
  background-color: white;
  color: var(--accent-color-dark, #1d3557);
  padding: 0.1rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.definition-cards-container {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.definition-card {
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  transform-origin: center;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
  position: relative;
  overflow: hidden;
  padding: 1.5rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.definition-card:hover {
  transform: translateY(-3px) scale(1.01);
  border-color: rgba(74, 111, 165, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.definition-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: var(--accent-color, #457b9d);
  opacity: 0.5;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

.definition-card:hover::before {
  opacity: 1;
  background-color: var(--accent-color, #457b9d);
}

.definition-number {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: var(--accent-color-light, #a8dadc);
  color: var(--accent-color-dark, #1d3557);
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0.1rem 0.4rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

.definition-card:hover .definition-number {
  opacity: 1;
  transform: scale(1.05);
  background-color: var(--accent-color, #457b9d);
  color: white;
}

.definition-content {
  padding-right: 1.5rem;
}

.definition-text {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text-primary, #1d3557);
  font-weight: 400;
  position: relative;
}

.definition-details {
  display: none;
  margin-top: 0.75rem;
}

.definition-details.visible {
  display: block;
}

.expand-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--accent-color, #457b9d);
  cursor: pointer;
}

.expand-icon {
  font-size: 0.625rem;
}

/* Examples and Usage Notes */
.examples, .usage-notes {
  background-color: rgba(0, 0, 0, 0.01);
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
  border-left: 3px solid var(--accent-color-light, #a8dadc);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
  position: relative;
}

.examples h4, .usage-notes h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: var(--accent-color-dark, #1d3557);
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(74, 111, 165, 0.08);
  padding-bottom: 0.25rem;
}

.examples h4::before {
  content: "💬";
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.usage-notes h4::before {
  content: "📝";
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.examples ul, .usage-notes ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.examples li, .usage-notes li {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--text-primary, #1d3557);
  position: relative;
  padding-left: 1rem;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.03);
}

.examples li:last-child, .usage-notes li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.examples li::before, .usage-notes li::before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: var(--accent-color, #457b9d);
  font-weight: bold;
}

.examples li em, .usage-notes li em {
  color: var(--accent-color-dark, #1d3557);
  font-style: normal;
  font-weight: 500;
  background-color: rgba(74, 111, 165, 0.05);
  padding: 0 0.2rem;
  border-radius: 2px;
}

.examples li .translation, .usage-notes li .note-detail {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--text-secondary, #6c757d);
  font-style: italic;
  padding-left: 0.5rem;
  border-left: 2px solid rgba(0, 0, 0, 0.03);
}

/* Idioms section */
.idioms-section {
  margin-bottom: var(--section-spacing);
  padding: var(--inner-spacing);
  background-color: #f0f4f8;
  border-radius: var(--card-radius);
  border-left: 3px solid #5e81ac;
}

.idioms-section h3 {
  margin-top: 0;
  margin-bottom: var(--inner-spacing);
  font-size: 1.25rem;
  color: #5e81ac;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.idioms-section h3::before {
  content: "💭";
  margin-right: 0.5rem;
  font-size: 1.1rem;
}

.idioms-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--inner-spacing);
}

.idiom-card {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: var(--card-radius);
  padding: var(--inner-spacing);
  transition: all 0.2s ease;
  box-shadow: none;
}

.idiom-card:hover {
  transform: translateY(-2px);
  border-color: rgba(94, 129, 172, 0.3);
}

.idiom-phrase,
.idiom-text {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #5e81ac;
}

.idiom-meaning {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.5;
}

.meaning-label,
.example-label {
  font-weight: 600;
  color: var(--text-secondary);
}

.idiom-example {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-style: italic;
  color: var(--text-secondary);
  line-height: 1.5;
  background-color: #f8f9fa;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.idiom-source {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--text-secondary);
  padding: 0.1rem 0.4rem;
  border-radius: 12px;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

/* Relations section */
.relations-section {
  margin-bottom: var(--section-spacing);
}

.relations-section h3 {
  margin-top: 0;
  margin-bottom: var(--inner-spacing);
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.relations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--inner-spacing);
}

.relation-group {
  background-color: #f8f9fa;
  border-radius: var(--card-radius);
  padding: var(--inner-spacing);
  box-shadow: var(--card-shadow);
  transition: all 0.2s ease;
}

.relation-group:hover {
  box-shadow: var(--card-shadow-hover);
  transform: translateY(-2px);
}

.relation-group h4 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: var(--text-primary);
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.synonyms h4::before {
  content: "🔄";
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.antonyms h4::before {
  content: "⚔️";
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.variants h4::before {
  content: "🔀";
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.related h4::before {
  content: "🔗";
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.derived h4::before {
  content: "🌱";
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.root h4::before {
  content: "🌳";
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.relation-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.relation-tag {
  background-color: white;
  border: 1px solid var(--border-color);
  padding: 0.3rem 0.75rem;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.25s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.relation-tag:hover {
  background-color: var(--accent-color);
  color: white;
  border-color: var(--accent-color);
  transform: translateY(-2px);
}

.root-tag {
  background-color: rgba(46, 125, 50, 0.1);
  color: var(--color-root);
  border-color: rgba(46, 125, 50, 0.2);
}

.root-tag:hover {
  background-color: var(--color-root);
  color: white;
}

/* Baybayin section */
.baybayin-section {
  margin-bottom: var(--space-lg);
  padding: var(--space-lg);
  background: linear-gradient(135deg, 
    hsla(var(--accent-hue), calc(var(--accent-saturation) - 20%), calc(var(--accent-lightness) + 5%), 0.1) 0%, 
    hsla(var(--accent-hue), calc(var(--accent-saturation) - 30%), calc(var(--accent-lightness) - 5%), 0.05) 100%);
  border-radius: var(--radius-md);
  border-left: 3px solid var(--color-etymology);
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
}

.baybayin-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;
  background: radial-gradient(circle, 
    hsla(var(--accent-hue), calc(var(--accent-saturation) - 10%), calc(var(--accent-lightness) + 10%), 0.15) 0%, 
    transparent 70%);
  z-index: 0;
  opacity: 0.5;
}

.baybayin-section h3 {
  margin-top: 0;
  margin-bottom: var(--space-md);
  font-size: var(--text-xl);
  color: var(--color-etymology);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.baybayin-section h3::before {
  content: "𑁋";
  font-size: 1.2em;
  opacity: 0.8;
}

.baybayin-display {
  text-align: center;
  padding: var(--space-xl);
  background-color: var(--card-bg);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-md);
  margin: var(--space-md) 0 var(--space-xl);
  border: 1px solid hsla(var(--accent-hue), 30%, 80%, 0.2);
  position: relative;
  overflow: hidden;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.baybayin-display:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-lg);
}

.baybayin-display::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at top right, 
    hsla(var(--accent-hue), 30%, 50%, 0.05) 0%, 
    transparent 70%);
  z-index: 0;
}

.baybayin-text {
  font-size: 3.5rem;
  margin: 0 0 var(--space-md) 0;
  color: var(--color-etymology);
  line-height: 1.2;
  position: relative;
  z-index: 1;
  font-family: "Baybayin Modern", sans-serif;
  letter-spacing: 0.1em;
  text-shadow: 0 2px 10px hsla(var(--accent-hue), 30%, 50%, 0.2);
  transition: transform var(--transition-fast);
}

.baybayin-display:hover .baybayin-text {
  transform: scale(1.05);
}

.romanized-text {
  font-size: var(--text-lg);
  color: var(--text-color-light);
  margin: 0;
  position: relative;
  z-index: 1;
}

.baybayin-info {
  background-color: var(--card-bg);
  border-radius: var(--radius-md);
  padding: var(--space-md);
  box-shadow: var(--shadow-sm);
  border: 1px solid hsla(0, 0%, 0%, 0.05);
  position: relative;
  z-index: 1;
}

.baybayin-info h4 {
  margin-top: 0;
  margin-bottom: var(--space-sm);
  font-size: var(--text-base);
  color: var(--color-etymology);
  font-weight: var(--font-weight-semibold);
}

.baybayin-info p {
  font-size: var(--text-sm);
  line-height: var(--leading-relaxed);
  color: var(--text-color);
  margin-bottom: var(--space-md);
}

.baybayin-character-guide {
  margin-top: var(--space-md);
  padding-top: var(--space-md);
  border-top: 1px solid hsla(0, 0%, 0%, 0.05);
}

.character-row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-sm);
  justify-content: center;
}

.baybayin-character {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2xs);
}

.baybayin-character .character {
  font-size: 1.5rem;
  color: var(--color-etymology);
  font-family: "Baybayin Modern", sans-serif;
  background-color: hsla(var(--accent-hue), 30%, 90%, 0.2);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-sm);
  border: 1px solid hsla(var(--accent-hue), 30%, 50%, 0.1);
  transition: all var(--transition-fast);
  cursor: pointer;
}

.baybayin-character .character:hover {
  transform: scale(1.1);
  background-color: hsla(var(--accent-hue), 30%, 90%, 0.3);
  box-shadow: var(--shadow-md);
}

/* Dark mode adjustments for Baybayin section */
.dark .baybayin-section {
  background: linear-gradient(135deg, 
    hsla(var(--accent-hue), calc(var(--accent-saturation) - 30%), 20%, 0.15) 0%, 
    hsla(var(--accent-hue), calc(var(--accent-saturation) - 40%), 15%, 0.05) 100%);
  border-color: hsla(var(--accent-hue), 30%, 50%, 0.3);
}

.dark .baybayin-display {
  background-color: var(--card-bg-dark);
  border-color: hsla(var(--accent-hue), 30%, 50%, 0.15);
}

.dark .baybayin-text {
  color: hsla(var(--accent-hue), 70%, 70%, 1);
  text-shadow: 0 2px 10px hsla(var(--accent-hue), 70%, 40%, 0.3);
}

.dark .baybayin-info {
  background-color: var(--card-bg-dark);
  border-color: hsla(0, 0%, 100%, 0.05);
}

.dark .baybayin-character .character {
  background-color: hsla(var(--accent-hue), 30%, 30%, 0.2);
  border-color: hsla(var(--accent-hue), 30%, 50%, 0.2);
  color: hsla(var(--accent-hue), 70%, 70%, 1);
}

.dark .baybayin-character .character:hover {
  background-color: hsla(var(--accent-hue), 30%, 40%, 0.3);
}

/* Responsive adjustments for Baybayin section */
@media (max-width: 768px) {
  .baybayin-section {
    padding: var(--space-md);
  }
  
  .baybayin-display {
    padding: var(--space-md);
  }
  
  .baybayin-text {
    font-size: 2.5rem;
  }
  
  .romanized-text {
    font-size: var(--text-base);
  }
  
  .character-row {
    gap: var(--space-xs);
  }
  
  .baybayin-character .character {
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
  }
}

/* Draggable Word Details Container */
.word-details-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  resize: horizontal;
  overflow: auto;
  min-width: 300px;
  max-width: 800px;
  width: 400px;
  transition: width 0.1s ease;
}

.word-details-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background-color: hsla(var(--primary-hue), 10%, 90%, 0.5);
  cursor: ew-resize;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.word-details-container:hover::after {
  opacity: 1;
}

.dark .word-details-container::after {
  background-color: hsla(var(--primary-hue), 10%, 30%, 0.5);
}

/* Enhanced Word Details */
.word-details {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color-light) transparent;
  border-radius: var(--radius-md);
  box-shadow: var(--card-shadow);
  background-color: var(--card-bg);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  will-change: transform;
  contain: content;
}

.word-details::-webkit-scrollbar {
  width: 6px;
}

.word-details::-webkit-scrollbar-track {
  background: transparent;
}

.word-details::-webkit-scrollbar-thumb {
  background-color: var(--accent-color-light);
  border-radius: var(--radius-pill);
}

/* Responsive adjustments for word details */
@media (max-width: 992px) {
  .word-details-container {
    width: 350px;
    min-width: 250px;
  }
}

@media (max-width: 768px) {
  .word-details-container {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    resize: none;
    height: auto;
    flex: 1;
  }
  
  .word-details-container::after {
    display: none;
  }
  
  .word-details {
    border-radius: 0;
    box-shadow: none;
  }
}

/* Print styles for Baybayin section */
@media print {
  .baybayin-section {
    background: none;
    border: 1px solid #ddd;
    box-shadow: none;
    break-inside: avoid;
  }
  
  .baybayin-display {
    box-shadow: none;
    border: 1px solid #ddd;
  }
  
  .baybayin-text {
    color: #000;
    text-shadow: none;
  }
  
  .baybayin-info {
    box-shadow: none;
    border: 1px solid #ddd;
  }
  
  .baybayin-character .character {
    border: 1px solid #ddd;
    background: none;
  }
}

/* Reduced motion preferences */
@media (prefers-reduced-motion: reduce) {
  .baybayin-display:hover,
  .baybayin-display:hover .baybayin-text,
  .baybayin-character .character:hover {
    transform: none;
    transition: none;
  }
}

/* Word Content Tabs */
.word-content-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: var(--card-bg-light);
  border-bottom: 1px solid var(--card-border);
}

.tab-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--card-border);
  border-radius: var(--radius-md);
  cursor: pointer;
  font-size: var(--text-sm);
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tab-button:hover {
  background-color: var(--hover-bg);
  transform: translateY(-1px);
}

.tab-button.active {
  background-color: var(--accent-color);
  color: white;
  border-color: var(--accent-color);
}

.tab-icon {
  font-size: 1.1em;
}

.word-content {
  padding: 1.5rem;
}

.word-details::-webkit-scrollbar {
  width: 6px;
}

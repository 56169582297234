:root {
  --bg-color: #f8f9fa;
  --text-color: #1d3557;
  --primary-color: #1d3557;
  --secondary-color: #e63946;
  --accent-color: #fca311;
  --gold: #d4af37;
  --card-bg-color: #ffffff;
  --card-border-color: #e6e8eb;
  --input-bg-color: #ffffff;
  --input-border-color: #d1d5db;
  --button-color: #1d3557;
  --button-text-color: #ffffff;
  --graph-bg-color: #f1f5f9;
  --color-main: #1d3557;
  --color-derivative: #457b9d;
  --color-etymology: #2a9d8f;
  --color-root: #e63946;
  --color-associated: #fca311;
  --color-default: #6c757d;
  --node-text-color: #1d3557;
  --link-color: #1d3557;
  --selected-node-border-color: #e6e8eb;
  --card-bg-color-rgb: 255, 255, 255; /* for light theme */
}

.dark {
  --bg-color: #0b0f1d;
  --header-color: #0b0f1d;
  --footer-color: #0b0f1d;
  --text-color: #d1c4a9;
  --text-color-white: #e0e4f5;
  --primary-color: #ffd166;
  --secondary-color: #b82e3d;
  --accent-color: #e09f3e;
  --gold: #d4af37;
  --red: #e63946;
  --source-color: #4a6fa5;
  --card-bg-color: #12161f;
  --card-border-color: #2e3a4e;
  --input-bg-color: #141824;
  --definition-bg-color: #151a28;
  --input-border-color: #2e3a4e;
  --button-color: var(--text-color);
  --button-text-color: #0b0f1d;
  --graph-bg-color: #202636;
  --link-color: #5c7a99;
  --color-main: #1d3557;
  --color-derivative: #457b9d;
  --color-etymology: #2a9d8f;
  --color-root: #e63946;
  --color-associated: #fca311;
  --color-default: #6c757d;
  --node-text-color: #e0e0e0;
  --selected-node-border-color: var(--text-color);
  --card-bg-color-rgb: 18, 22, 31; /* for dark theme */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100vh;
  overflow: hidden;
}

.word-explorer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.dark .header-content {
  background-color: var(--header-color);
  color: var(--primary-color);
}

h1 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

main {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.graph-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.graph-content {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.details-container {
  padding: 0;
  overflow-y: auto;
  background-color: white;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}

.no-word-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: var(--text-secondary-color);
  padding: 2rem;
  background-color: var(--card-bg-color);
  border-radius: var(--radius-md);
  box-shadow: var(--card-shadow);
}

.no-word-selected p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.word-details h2 {
  color: var(--secondary-color);
  padding-bottom: 0.25rem;
}

.definition-card {
  background-color: var(--definition-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: 4px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.definition-card h3 {
  color: var(--secondary-color);
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.definition-card ol {
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
}

.definition-card ol > li {
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.definition-card .source {
  font-size: 0.8rem;
  color: var(--accent-color);
  font-style: italic;
  display: block;
  margin-top: 0.25rem;
}

.dark .definition-card .source {
  color: var(--link-color);
}

.word-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.word-list li {
  background-color: var(--input-bg-color);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.9rem;
  border: 1px solid var(--accent-color);
}

.dark .word-list li {
  border: 1px solid var(--color-root);
}

.search-container {
  display: flex;
  align-items: stretch;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: var(--card-bg-color);
  border-bottom: 1px solid var(--card-border-color);
}

.search-input-container {
  position: relative;
  flex-grow: 1;
  display: flex;
}

.search-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 24px;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.3);
}

.search-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  height: 100%;
  align-self: stretch;
}

.search-button:hover {
  background-color: var(--primary-color);
}

.search-button:active {
  transform: scale(0.95);
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--input-bg-color);
  border: 1px solid var(--input-border-color);
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-suggestions li {
  padding: 10px;
  cursor: pointer;
  color: var(--text-color);
}

.search-suggestions li:hover {
  background-color: var(--accent-color);
  color: var(--bg-color);
}

.word-explorer.dark .search-suggestions {
  background-color: var(--card-bg-color);
  border-color: var(--card-border-color);
}

.word-explorer.dark .search-suggestions li:hover {
  background-color: var(--primary-color);
}

.search-loading {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .search-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    font-size: 14px;
    padding: 10px 14px;
  }

  .search-suggestions li {
    padding: 10px 14px;
  }

  input[type="text"], button {
    width: 100%;
  }

  .word-details h2 {
    font-size: 1.3rem;
  }

  .definition-card {
    padding: 0.5rem;
  }

  .definition-card h3 {
    font-size: 1rem;
  }

  .word-list li {
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
  }
}

input[type="text"] {
  flex: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  background-color: var(--input-bg-color);
  color: var(--text-color);
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

button:active {
  transform: scale(0.95);
}

button:hover {
  background-color: var(--primary-color);
}

.controls-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--card-bg-color);
  border-top: 1px solid var(--card-border-color);
}

.zoom-button, .reset-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.graph-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-grow: 1;
}

.slider-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 150px;
}

.footer {
  background-color: var(--text-color);
  color: var(--bg-color);
  text-align: center;
  padding: 0.25rem;
  font-size: 0.8rem;
  border-top: 1px solid var(--accent-color);
}

.dark .footer {
  background-color: var(--primary-color);
  color: var(--bg-color);
  border-top: 1px solid var(--gold);
}

.history-button {
  padding: 0.5rem;
  font-size: 1.2rem;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.3s ease;
}

.history-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 1024px) {
  .word-explorer {
    height: auto;
    min-height: 100vh;
  }

  main {
    flex-direction: column;
  }

  .graph-container {
    height: 50vh;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.1rem;
  }

  .theme-toggle, .history-button {
    padding: 0.25rem;
    font-size: 0.8rem;
  }

  .word-details h2 {
    font-size: 1.2rem;
  }

  .definition-card h3 {
    font-size: 0.9rem;
  }

  .word-list li {
    font-size: 0.7rem;
    padding: 0.1rem 0.3rem;
  }

  .footer {
    font-size: 0.7rem;
    padding: 0.4rem;
  }
}

.loading-indicator {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--button-text-color);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 0.5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: var(--text-secondary-color);
  padding: 2rem;
  background-color: var(--card-bg-color);
  border-radius: var(--radius-md);
  box-shadow: var(--card-shadow);
  font-size: 1.1rem;
}

.loading-spinner::before {
  content: "";
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  border: 4px solid var(--card-border-color);
  border-top: 4px solid var(--accent-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Note: The word-details-container class is defined in global.css */

/* Word Content Tabs */
.word-content-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.tab-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tab-button:hover {
  background-color: #f1f5f9;
  transform: translateY(-1px);
}

.tab-button.active {
  background-color: var(--accent-color, #fca311);
  color: white;
  border-color: var(--accent-color, #fca311);
}

.tab-icon {
  font-size: 1.1em;
}

.word-content {
  padding: 1.5rem;
}

/* Fix for empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 0.5rem;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.empty-state p {
  margin-bottom: 1rem;
  color: var(--text-secondary, #6c757d);
}

.fetch-network-button {
  padding: 0.5rem 1rem;
  background-color: var(--accent-color, #fca311);
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.fetch-network-button:hover {
  background-color: var(--accent-color-dark, #e76f51);
  transform: translateY(-1px);
}

.graph-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.graph-svg-container {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-svg {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}

.graph-svg.loading {
  opacity: 0.3;
}

.graph-svg.loaded {
  opacity: 1;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block; /* Prevents extra spacing */
}

.graph-content {
  width: 100%;
  height: 100%;
}

.controls-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 15px;
  background-color: var(--controls-background-color, #e9ecef);
  border-top: 1px solid var(--border-color, #dee2e6);
  z-index: 10;
  backdrop-filter: blur(10px);
}

.zoom-controls {
  display: flex;
  gap: 5px;
}

.zoom-button {
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.1s;
}

.zoom-button:hover {
  background-color: var(--primary-color);
}

.zoom-button:active {
  transform: scale(0.95);
}

.graph-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.slider-container {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 150px;
}

.MuiSlider-root {
  width: 100% !important;
  color: var(--accent-color) !important;
}

.MuiTypography-caption {
  font-size: 0.9rem !important;
  color: var(--text-color) !important;
  min-width: 90px;
}

.node {
  cursor: pointer;
}

.node circle {
  stroke: var(--card-bg-color);
  stroke-width: 2px;
  transition: stroke-width 0.3s, stroke 0.3s, r 0.3s;
}

.node.selected circle {
  stroke-width: 4px;
  stroke: var(--selected-node-border-color);
}

.node text {
  font-size: 10px;
  font-weight: bold;
  fill: var(--node-text-color);
  pointer-events: none;
  user-select: none;
}

.link {
  stroke-opacity: 0.6;
  stroke-width: 1px;
  transition: stroke-opacity 0.3s, stroke-width 0.3s;
}

.link:hover {
  stroke-opacity: 1;
  stroke-width: 2px;
}

.node-tooltip {
  position: absolute;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  pointer-events: none;
  z-index: 10;
  max-width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}

.legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  max-width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-box {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .controls-container {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    padding: 15px;
    justify-content: center;
  }

  .zoom-controls {
    justify-content: center;
  }

  .graph-container {
    height: calc(100vh - 220px);
    min-height: 300px;
  }

  .graph-controls {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    gap: 15px;
  }

  .slider-container {
    width: 100%;
  }

  .MuiSlider-root {
    width: 100% !important;
  }

  .legend {
    position: static;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    max-width: none;
    margin-top: 15px;
  }

  .legend-item {
    font-size: 12px;
  }

  .node-tooltip {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .controls-container {
    padding: 10px;
  }

  .zoom-button {
    padding: 6px 12px;
    font-size: 13px;
  }

  .slider-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .MuiTypography-caption {
    font-size: 0.8rem !important;
    min-width: auto;
  }

  .node-tooltip {
    font-size: 11px;
    padding: 8px;
    max-width: 150px;
  }

  .node-tooltip h3 {
    font-size: 13px;
  }

  .legend {
    font-size: 10px;
    gap: 8px;
  }

  .color-box {
    width: 10px;
    height: 10px;
  }
}

.loading-overlay, .error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.spinner {
  border: 4px solid var(--accent-color);
  border-top: 4px solid var(--background-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: var(--error-text-color);
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
}

.retry-button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: var(--primary-color);
}

.graph-legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  box-shadow: var(--shadow-md);
  max-width: 250px;
  z-index: 100;
  font-size: 12px;
  max-height: calc(100% - 100px);
  overflow-y: auto;
  opacity: 0.85;
  transition: opacity 0.2s ease;
}

.graph-legend:hover {
  opacity: 1;
}

.legend-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 6px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.legend-label {
  font-size: 12px;
  color: var(--text-color);
}